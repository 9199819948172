/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
import gsap from "gsap";
import { useEffect, useRef } from "react";
import { faqs } from "../data";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React from "react";
export default function Faq(_a) {
    gsap.registerPlugin(ScrollTrigger);
    var revealRefs = useRef([]);
    revealRefs.current = [];
    useEffect(function () {
        var ctx = gsap.context(function () {
            revealRefs.current.forEach(function (el, index) {
                gsap.fromTo(el, {
                    autoAlpha: 0,
                }, {
                    duration: 1,
                    autoAlpha: 1,
                    ease: "none",
                    scrollTrigger: {
                        id: "section-".concat(index + 1),
                        trigger: el,
                        start: "top bottom",
                        // stagger: 2,
                        toggleActions: "play none none reverse",
                    },
                });
            });
        });
        return function () { return ctx.revert(); };
    }, []);
    var addToRefs = function (el) {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };
    return (React.createElement("section", { id: "faq", className: "bg-white pt-20" },
        React.createElement("div", { className: "container px-6 py-12 mx-auto" },
            React.createElement("h1", { className: "text-2xl font-semibold text-gray-700 lg:text-3xl " }, "\u88DD\u6F62\u5927\u5C0F\u4E8B\u5E38\u898B\u554F\u7B54"),
            React.createElement("p", { className: "mt-4 text-gray-500 xl:mt-6 " }, "\u6211\u5011\u5C07\u5E38\u898B\u554F\u984C\u641C\u96C6\uFF0C\u63D0\u4F9B\u7D66\u60A8\u66F4\u9032\u4E00\u6B65\u7684\u670D\u52D9\u3002"),
            React.createElement("div", { className: "grid grid-cols-1 gap-8 mt-8 lg:mt-16 md:grid-cols-2 xl:grid-cols-3" }, faqs.map(function (key, index) { return (React.createElement("div", { key: key.question, className: "faq-wrapper", ref: addToRefs },
                React.createElement("div", { className: "inline-block p-3 text-white bg-blue-600 rounded-lg " },
                    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", className: "w-6 h-6", fill: "none", viewBox: "0 0 24 24", stroke: "currentColor" },
                        React.createElement("path", { "stroke-linecap": "round", "stroke-linejoin": "round", "stroke-width": "2", d: "M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" }))),
                React.createElement("div", null,
                    React.createElement("h1", { className: "text-xl font-semibold text-gray-700 " }, key.question),
                    React.createElement("p", { className: "mt-2 text-sm text-gray-500 " }, key.answer)))); })))));
}
