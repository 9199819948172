/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
import React from "react";
export default function Price(_a) {
    return (React.createElement("div", { id: "price", className: "bg-white dark:bg-gray-800 h-screen overflow-hidden relative lg:flex lg:items-center" },
        React.createElement("div", { className: "mt-20 w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20" },
            React.createElement("h1", { className: "text-4xl font-extrabold text-black dark:text-white sm:text-5xl" }, "\u5373\u523B\u555F\u52D5"),
            React.createElement("h2", { className: "text-3xl font-extrabold text-black dark:text-white sm:text-3xl mt-5" },
                React.createElement("span", { className: "block" }, "\u5927\u81BD\u8A2D\u8A08\u65C5\u7A0B \u5B9A\u5236\u60A8\u7684\u5922\u60F3\u5C45\u6240")),
            React.createElement("br", null),
            React.createElement("p", { className: "text-md mt-4 text-gray-400 sm:text-2xl" }, "\u8001\u5C4B\u7FFB\u65B0\u3001\u9810\u552E\u5C4B\u5BA2\u8B8A\u3001\u6BDB\u80DA\u623F\u3001\u8FA6\u516C\u7A7A\u9593\u8A2D\u8A08"),
            React.createElement("p", { className: "text-md mt-4 text-gray-400 sm:text-lg" }, "\u5F9E\u8A2D\u8A08\u5230\u5B8C\u5DE5\uFF0C\u6211\u5011\u7528\u5FC3\u8046\u807D\u60A8\u7684\u9700\u6C42\uFF0C\u70BA\u60A8\u6253\u9020\u4E00\u500B\u6EAB\u99A8\u3001\u8212\u9069\u4E14\u5177\u6709\u5275\u610F\u7684\u7A7A\u9593\u3002\u8B93\u6211\u5011\u4E00\u8D77\u70BA\u60A8\u5BE6\u73FE\u5922\u60F3\uFF0C\u5171\u5275\u7F8E\u597D\u672A\u4F86\u3002"),
            React.createElement("div", { className: "lg:mt-0 lg:flex-shrink-0 justify-center content-center" },
                React.createElement("div", { className: "mt-12 inline-flex rounded-md shadow" },
                    React.createElement("button", { type: "button", className: "py-2 px-4  bg-green-500 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg " }, "\u8207\u6211\u806F\u7D61")))),
        React.createElement("div", { className: "flex items-center gap-8 p-8 lg:p-24" },
            React.createElement("img", { src: "./images/slogan/1.jpg", className: "w-1/2 rounded-lg", alt: "Tree" }),
            React.createElement("div", null,
                React.createElement("img", { src: "./images/slogan/2.jpg", className: "mb-8 rounded-lg", alt: "Tree" }),
                React.createElement("img", { src: "./images/slogan/3.jpg", className: "rounded-lg", alt: "Tree" })))));
}
