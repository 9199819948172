import React from "react";
import ReactDOM from "react-dom/client";
import "../index.css";
import reportWebVitals from "../reportWebVitals";
import Faq from "./Faq";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Price from "./Price";
import Product from "./Product";
import Services from "./Services";
var root = ReactDOM.createRoot(document.getElementById("root"));
root.render(React.createElement(React.StrictMode, null,
    React.createElement(Navbar, null),
    React.createElement(Price, null),
    React.createElement(Product, null),
    React.createElement(Services, null),
    React.createElement(Faq, null),
    React.createElement(Footer, null)));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
