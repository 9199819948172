/* eslint-disable react/jsx-key */
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React, { Suspense, useEffect } from "react";
import { products } from "../data";
export default function Product(_a) {
    gsap.registerPlugin(ScrollTrigger);
    useEffect(function () {
        var ctx = gsap.context(function () {
            // ---------- selecting all horizontal sections
            var horizontalSections = gsap.utils.toArray(".horizontal-section");
            //---------- applying horizontal scroll animation
            gsap.to("#product-container", {
                xPercent: -100 * (horizontalSections.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: "#product-container",
                    pin: true,
                    scrub: 1,
                    //snap: 1 / (horizontalSections.length - 1),
                    end: function () { return "+=" + window.innerHeight; }
                }
            });
        });
        return function () { return ctx.revert(); };
    }, []);
    return (React.createElement("section", { id: "products", className: "bg-gradient-to-b w-full text-white md:h-screen text-center md:text-left" },
        React.createElement("div", { className: "w-full h-full overflow-hidden " },
            React.createElement("div", { id: "product-container", className: "relative w-full flex flex-row content-center" },
                products.map(function (key, index) { return (React.createElement("div", { key: key.title, className: "w-full min-w-full min-h-screen text-center content-center relative horizontal-section" },
                    React.createElement("div", { style: { backgroundImage: "url(".concat(key.url, ")") }, className: "w-full h-full  bg-[url:var(backgroundImage)] bg-no-repeat bg-center md:bg-repeat-x bg-cover section-contain" },
                        React.createElement("div", { className: "absolute bottom-0 bg-gray-500/50 w-full section-background p-5" },
                            React.createElement("h1", { className: "text-white font-semibold text-4xl" }, key.title),
                            React.createElement("p", { className: "text-gray-200" },
                                " ",
                                key.subtitle,
                                " "))))); }),
                React.createElement("div", { id: "progress" },
                    React.createElement("div", { id: "progressBar" })))),
        React.createElement(Suspense, { fallback: React.createElement("h1", null, "Loading...") })));
}
