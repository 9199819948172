import React from "react";
import { footerLinksColumnOne, footerLinksColumnTwo } from "../data";
var Footer = function (props) {
    return (React.createElement("footer", { className: "bg-[linear-gradient(133deg,_#566270_0%,_#283444_100%)]" },
        React.createElement("div", { className: "max-w-[1490px] mx-auto px-10 py-[120px]" },
            React.createElement("div", { className: "flex flex-col items-center xl:items-start xl:flex-row xl:justify-between gap-12" },
                React.createElement("div", { className: "flex flex-col md:flex-row justify-center items-center md:items-start gap-12 w-full" },
                    React.createElement("div", { className: "flex flex-col xs:flex-row gap-12 w-full md:w-auto" }, footerLinksColumnOne.map(function (item, index) { return (React.createElement("div", { key: item.title, className: "text-white max-w-screen-md md:max-w-[340px] flex-1 md:flex-auto" },
                        React.createElement("h6", { className: "mb-8 text-base lg:text-lg font-bold text-center xl:text-start" }, item.title),
                        React.createElement("ul", { className: "flex flex-col gap-4" }, item.links.map(function (link, i) { return (React.createElement("div", { key: "list-item-".concat(i), className: "w-full flex mx-auto justify-center xl:justify-start" },
                            React.createElement("li", { className: "font-medium text-sm md:text-base cursor-pointer text-center xl:text-start w-fit" }, link))); })))); })),
                    React.createElement("div", { className: "flex flex-col xs:flex-row gap-12 w-full md:w-auto" }, footerLinksColumnTwo.map(function (item, index) { return (React.createElement("div", { key: item.title, className: "text-white max-w-screen md:max-w-[340px] flex-1 md:flex-auto" },
                        React.createElement("h6", { className: "mb-8 text-base lg:text-lg font-bold text-center xl:text-start" }, item.title),
                        React.createElement("ul", { className: "flex flex-col gap-4" }, item.links.map(function (link, i) { return (React.createElement("div", { key: "list-item-".concat(i), className: "w-full flex mx-auto justify-center xl:justify-start" },
                            React.createElement("li", { className: "font-medium text-sm md:text-base ".concat(index !== 1 && "cursor-pointer", " text-center xl:text-start w-fit") }, link))); })))); })))),
            React.createElement("div", { className: "w-full h-[1px] bg-white my-12" }),
            React.createElement("h6", { className: "text-white text-center text-base lg:text-lg" }, "\u00A9 \u5468\u5927\u81BD\u5BA4\u5167\u88DD\u4FEE\u8A2D\u8A08 Bold Chou - All rights reserved"))));
};
export default Footer;
