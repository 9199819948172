/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-key */
import React from "react";
import { services } from "../data";
export default function Services(_a) {
    return (React.createElement("section", { id: "services", className: "dark:bg-gray-800 pt-20" },
        React.createElement("div", { className: "container px-6 py-10 mx-auto" },
            React.createElement("h1", { className: "text-3xl font-semibold  capitalize lg:text-4xl text-white" },
                " \u63D0\u4F9B\u512A\u826F",
                React.createElement("span", { className: "underline decoration-blue-500" }, "\u670D\u52D9")),
            React.createElement("p", { className: "mt-4 text-gray-300 xl:mt-6 " }, "\u6211\u5011\u4EE5\u5C08\u696D\u7684\u614B\u5EA6\u63D0\u4F9B\u4E00\u7CFB\u5217\u5BA4\u5167\u8A2D\u8A08\u8207\u5DE5\u7A0B\u670D\u52D9\uFF0C\u5F9E\u62C6\u9664\u5230\u88DD\u6F62\uFF0C\u7121\u4E00\u4E0D\u5C08\u696D\uFF0C\u7121\u4E00\u4E0D\u7CBE\u5F69\u3002\u6211\u5011\u7684\u5C08\u696D\u670D\u52D9\uFF1A"),
            React.createElement("div", { className: "grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3" }, services.map(function (key, index) { return (React.createElement("div", { key: key.title, className: "p-8 space-y-3 border-2 border-blue-400 rounded-xl" },
                React.createElement("span", { className: "inline-block text-blue-400" },
                    React.createElement("img", { src: key.icon, title: key.title, alt: "BoldChou", className: "rounded-md" })),
                React.createElement("h1", { className: "text-2xl font-semibold  capitalize text-white" }, key.title),
                React.createElement("p", { className: "text-gray-300 " }, key.subtitle))); })))));
}
;
