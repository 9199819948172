"use client";
import React from "react";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { HiMenuAlt3 } from "react-icons/hi";
import NavLink from "../components/NavLink";
export default function Navbar(_a) {
    var _b = useState(false), showMobileMenu = _b[0], setShowMobileMenu = _b[1];
    return (React.createElement("div", { className: "fixed top-0 left-0 w-full flex items-center justify-between mx-auto  px-6 py-3 transition-all rounded-b-md shadow-md bg-white z-50 backdrop-filter backdrop-blur-lg bg-opacity-20" },
        React.createElement("img", { src: "./navLogo.png", width: 80, alt: "BoldChou", title: "\u5468\u5927\u81BD\u5DE5\u4F5C\u5BA4" }),
        React.createElement("ul", { className: "hidden md:flex items-center gap-10 lg:gap-[68px]" },
            React.createElement(NavLink, { to: "products" }, "\u8A2D\u8A08\u96C6"),
            React.createElement(NavLink, { to: "services" }, "\u670D\u52D9"),
            React.createElement(NavLink, { to: "faq" }, "\u5E38\u898B\u554F\u7B54"),
            React.createElement(NavLink, { to: "price" }, "\u95DC\u65BC\u6211\u5011")),
        React.createElement(HiMenuAlt3, { size: 30, className: "block md:hidden cursor-pointer text-black", onClick: function () { return setShowMobileMenu(function (prev) { return !prev; }); } }),
        React.createElement("div", { className: "block md:hidden w-full fixed ".concat(!showMobileMenu ? "-top-[410px]" : "top-0", " left-0 bg-[#dde0e5] h-[410px] transition-all duration-[800ms] shadow-xl z-10 py-8 px-12 rounded-b-xl ") },
            React.createElement(AiOutlineClose, { size: 25, className: "absolute top-5 right-5 cursor-pointer", onClick: function () { return setShowMobileMenu(false); } }),
            React.createElement("ul", { className: "pt-[20px] items-center flex flex-col gap-8" },
                React.createElement(NavLink, { to: "services", mobileMenu: true }, "\u670D\u52D9"),
                React.createElement(NavLink, { to: "products", mobileMenu: true }, "\u7522\u54C1"),
                React.createElement(NavLink, { to: "reference", mobileMenu: true }, "\u5831\u50F9"),
                React.createElement(NavLink, { to: "care", mobileMenu: true }, "\u95DC\u65BC\u6211\u5011")))));
}
