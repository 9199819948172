export var heroTitle = "裝修那樣的大小事";
export var heroSubtitle = "設計─家的風格，從水電、板材、磁磚、油漆、家俱的整合，全部一手幫你包辦";
export var services = [
    {
        title: "室內設計",
        subtitle: "我們擁有一支專業的室內設計團隊，將根據您的需求和喜好，為您打造舒適、美觀的居家空間。",
        icon: "./images/service/室內設計.jpg",
    },
    {
        title: "拆除工程",
        subtitle: "安全、高效的拆除服務，為您清理出一片新的空間",
        icon: "./images/service/拆除.jpg",
        alt: "周大膽工作室"
    },
    {
        title: "泥做工程",
        subtitle: "精湛的泥作技術，打造平整、美觀的牆面。",
        icon: "./images/service/泥作工程.jpg",
    },
    {
        title: "水電工程",
        subtitle: "專業水電工程師，確保您的水電設備運作順暢、安全。",
        icon: "./images/service/水電.jpg",
    },
    {
        title: "木作工程",
        subtitle: "精心製作的木工設計，為您帶來溫暖、自然的氛圍。",
        icon: "./images/service/木作.jpg",
    },
    {
        title: "系統櫃工程",
        subtitle: "客製化的系統櫃設計，充分利用空間，實現您的收納夢想。",
        icon: "./images/service/系統櫃工程.jpg",
    },
    {
        title: "衛浴工程",
        subtitle: "豪華的衛浴設計，讓您每天都享受輕松愉悅的沐浴時光。",
        icon: "./images/service/衛浴.jpg",
    },
    {
        title: "木地板工程",
        subtitle: "高品質的木地板安裝服務，為您的家居增添溫暖和舒適。",
        icon: "./images/service/木地板.jpg",
    },
    {
        title: "壁紙/窗簾",
        subtitle: "多款風格的壁紙和窗簾選擇，讓您的空間更加個性化。",
        icon: "./images/service/壁紙窗簾油漆.jpg",
    },
    {
        title: "抓漏防水",
        subtitle: "專業的抓漏和防水服務，保護您的家居免受潮濕和漏水的困擾。",
        icon: "./images/service/防水抓漏.jpg",
    }
];
export var slogans = [
    {
        title: "客廳",
        subtitle: "",
        url: "./images/product/p1.jpg",
    },
    {
        title: "主臥房",
        subtitle: "",
        url: "./images/product/p2.jpg",
    },
    {
        title: "電視牆",
        subtitle: "",
        url: "./images/product/p3.jpg",
    },
];
export var prepares = [
    {
        title: "項目描述",
        subtitle: "請簡要描述您的項目，包括您的需求、計劃的空間（例如臥室、客廳、辦公室等）以及您的期望完成時間。"
    },
    {
        title: "預算範圍",
        subtitle: "請告訴我們您的預算範圍，這將有助於我們設計出符合您預期的方案。"
    },
    {
        title: "喜好和風格",
        subtitle: "您有什麼特別的喜好或是對風格有任何偏好嗎？例如歐式、現代、工業風格等等。"
    },
    {
        title: "需求細節",
        subtitle: "是否有任何特殊的需求或是限制條件需要我們考慮？例如家具尺寸、材料偏好、特殊功能需求等。"
    },
    {
        title: "聯絡方式",
        subtitle: "請提供您的聯絡方式，包括電話號碼和電子郵件地址，以便我們能夠及時與您聯絡。"
    }
];
export var products = [
    {
        class: "住家",
        title: "光亮客廳",
        subtitle: "潔白光亮風",
        alt: "",
        url: "./images/product/p1.jpg",
    },
    {
        class: "住家",
        title: "大理石客廳",
        subtitle: "北歐風格",
        alt: "",
        url: "./images/product/p6.jpg",
    },
    {
        class: "住家",
        title: "優雅寬敞客廳",
        subtitle: "北歐風格",
        alt: "",
        url: "./images/product/p7.jpg",
    },
    {
        class: "住家",
        title: "客廳",
        subtitle: "北歐風格",
        alt: "",
        url: "./images/product/p8.jpg",
    },
    {
        class: "住家",
        title: "典雅客廳",
        subtitle: "書香氣息的典雅客廳",
        alt: "",
        url: "./images/product/p3.jpg",
    },
    {
        class: "住家",
        title: "清新主臥房",
        subtitle: "清新優雅的房間",
        alt: "",
        url: "./images/product/p2.jpg",
    },
    {
        class: "住家",
        title: "讀書間",
        subtitle: "享受閱讀樂趣",
        alt: "",
        url: "./images/product/p4.png",
    },
    {
        title: "開放式廚房",
        subtitle: "空間廚房與餐桌的寬敞結合",
        alt: "",
        url: "./images/product/p5.png",
    },
    {
        class: "住家",
        title: "大理石會議桌",
        subtitle: "北歐風格",
        alt: "會議桌",
        url: "./images/product/p10.jpg",
    },
    {
        class: "辦公室",
        title: "辦公室",
        subtitle: "北歐風格",
        alt: "",
        url: "./images/product/p9.jpg",
    },
];
export var faqs = [
    {
        question: "公司有哪些室內設計或裝潢服務？",
        answer: "我們提供一系列專業的室內設計與裝休或裝潢服務，室內設計：丈量、室內設計、出圖裝修或裝潢工程：如拆除工程、泥做工程、水電工程、木作工程、系統櫃工程、衛浴工程、木地板工程、壁紙/窗簾、抓漏防水等服務。",
        url: "/product/p1.jpg",
    },
    {
        question: "我們如何與設計師合作？",
        answer: "您可以通過即時通訊、電話或郵件與我們聯繫，我們將安排一位專業的設計師與您進行會面，了解您的需求並提供相應的設計方案。",
        url: "/product/p1.jpg",
    },
    {
        question: "設計和工程周期是多久？",
        answer: "設計和工程周期根據項目的複雜程度和範圍而定，多數都在3-4個月內完工；一般來說，我們將在確認設計方案後盡快開始工程，並努力在預定時間內完成",
        url: "/product/p1.jpg",
    },
    {
        question: "可以看到你們之前的作品？",
        answer: "是的，您可以在我們的網站上查看我們之前的作品，也可以直接與我們聯繫，我們將安排一位專業設計師與您分享我們的成功案例。",
        url: "/product/p1.jpg",
    },
    {
        question: "我們可以參與設計過程嗎？",
        answer: "是的，我們非常歡迎客戶參與設計過程，我們將與您合作，確保最終的設計方案符合您的需求和期望。",
        url: "/product/p1.jpg",
    },
    {
        question: "你們的團隊是否具有專業資質？",
        answer: "是的，我們的室內設計團隊具有室內設計及裝修乙級技術證；且工程團隊擁有豐富的經驗和專業資質，確保工程的品質和安全。",
        url: "/product/p1.jpg",
    },
    {
        question: "你們可以提供智能家居設備嗎？",
        answer: "是的，我們可以提供智能家居設備，並與客戶提供的淘寶或智能家居店合作，以滿足客戶的需求。",
        url: "/product/p1.jpg",
    },
    {
        question: "你們的工程是否包括清理和後期維護？",
        answer: "是的，我們的工程包括清理和後期維護，確保您空間清潔、整潔和良好狀態。",
        url: "/product/p1.jpg",
    },
    {
        question: "你們是否提供免費報價服務？",
        answer: "是的，我們提供免費的報價服務，您可以通過填寫我們的在線報價表格或與我們聯繫，我們將為您提供專業的報價方案。",
        url: "/product/p1.jpg",
    },
    {
        question: "你們的價格如何？",
        answer: "我們的價格根據項目的複雜程度、範圍和客戶的需求而定，歡迎您留下聯絡方式再電話確認。",
        url: "/product/p1.jpg",
    },
];
export var productsTitle = "Best Selling Products";
export var productsBtnText = "See Colection";
export var referenceTitle = "Interior Plants Reference";
export var referenceSubtitle = "make your home cozy with refreshing plants";
export var careTitle = "How to care for plants";
export var careSubtitle = "Take care of plants with all your heart";
export var newsletter = "Newsletter";
export var footerLinksColumnOne = [
    {
        title: "支援",
        links: ["關於我們"],
    },
    {
        title: "好用連結",
        links: ["服務條款", "隱私"],
    },
];
export var footerLinksColumnTwo = [
    {
        title: "連絡方式",
        links: ["admin@boldchou.com"],
    },
];
